li.game-icon {
    font-family: geom-graphic, sans-serfi;
    font-weight: 600;
    font-style : italic;

    background-color: #000000;
    width: 60px;
    height: 60px;
    border-radius: 10px;
}

li.game-icon>a {
    text-decoration: none;

    color: #569447;
    font-size: 2em;
    width: 60px;
    line-height: 60px;
    display: block;
    text-align: center;
}
